import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-confirmation-message-inside',
  templateUrl: './confirmation-message-inside.component.html',
  styleUrls: ['./confirmation-message-inside.component.css']
})
export class ConfirmationMessageInsideComponent implements OnInit {

  message: string

  constructor(private auth: AuthService) { }

  ngOnInit() {
    this.auth.msgConfirmation$.pipe(take(1))
        .subscribe(msg => this.message = msg)
  }

}
