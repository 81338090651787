import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CustomValidatorService } from 'src/app/services/custom-validator.service';

@Component({
  selector: 'app-change-recovered-password',
  templateUrl: './change-recovered-password.component.html',
  styleUrls: ['./change-recovered-password.component.css']
})
export class ChangeRecoveredPasswordComponent implements OnInit {
  token: string
  recoveryPasswordForm: FormGroup

  constructor(private formBuilder: FormBuilder, private auth: AuthService, private router: Router,
              private custom: CustomValidatorService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.token = params.get('token')
    })
    this.recoveryPasswordForm = this.formBuilder.group({
      token: this.token,
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]      
    },{
      validator: this.custom.MustMatch('password', 'confirmPassword')
    })  

  }

  submitRecoveryPassword() {
    if (this.recoveryPasswordForm.valid){
      this.auth.sendTokenAndPassword(this.recoveryPasswordForm.value).subscribe(
        res => {
          const msg = res
          this.sendMsgConfirmation(msg)
          this.router.navigateByUrl('/auth/msg-confirmation-outside')
        },
        err => {
          const msg = err.error.detail
          this.sendMsgConfirmation(msg)          
          console.error(err)
          this.router.navigateByUrl('/auth/msg-confirmation-outside')
        }
      )
    }
  }

  sendMsgConfirmation(text: string): void{
    this.auth.sendMsg(text)
  }

}
