import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { IndexService } from 'src/app/services/index.service';
import { NewsService } from 'src/app/services/news.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { PricesService } from 'src/app/services/prices.service';

@Component({
  selector: 'app-new-index',
  templateUrl: './new-index.component.html',
  styleUrls: ['./new-index.component.css'],
  styles: [
    `
      .greenClass { color: green }
      .redClass { color: red }
    `
  ]
})
export class NewIndexComponent implements OnInit {

  companies: string[] = []
  numberOfNews: number[] = []
  lastPrices: string[] = []
  profitList: string[] = []
  tickers: string[] = []
  form: FormGroup
  isLoaded: boolean = false
  
  
  //Here i uncheck all checkboxes
  @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef>;

  constructor(private news: NewsService, private formBuilder: FormBuilder, 
    private ind: IndexService, private price: PricesService, private pf: PortfolioService) {
    this.form = this.formBuilder.group({
      companies: this.formBuilder.array([])
  })
  }

  ngOnInit(): void {
    this.ind.getNewIndex()
    .subscribe(
      (data) => { // Success
        this.companies = data['companies']
      },
      (err) => {
        console.error(err.error.detail);
      }
    )
    this.news.getNumberOfNews()
    .subscribe(
      (data) => { // Success
        this.numberOfNews = data
      },
      (err) => {
        console.error(err.error.detail);
      }
    )
    //Close Prices
    this.price.getLastPrices()
    .subscribe(
      (data) => { // Success
        this.lastPrices = data
      },
      (err) => {
        console.error(err.error.detail);
      }
    )
    
    //Profits
    this.price.getProfitabilities()
    .subscribe(
      (data) => { // Success
        this.profitList = data
        this.isLoaded = true
      },
      (err) => {
        console.error(err.error.detail);
      }
    )

    this.ind.getTickers()
    .subscribe(
      (data) => { // Success
        this.tickers = data        
      },
      (err) => {
        console.error(err.error.detail);
      }
    )

  
    this.pf.setPriceCurrent()
    .subscribe(
      (data) => { // Success
      }
    )
  

  }

  onCheckboxChange(e) {
    const companiess: FormArray = this.form.get('companies') as FormArray
    
    //If click in checkbox, it adds in list
    if (e.target.checked) {
      companiess.push(new FormControl(e.target.value))
    } else {
      let i: number = 0;
      //Here checks when they are unchecked
      companiess.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          companiess.removeAt(i)
          return
        }
        i++
      });
    }
  }

  deleteCompaniesFromIndex() {
    this.ind.deleteCompaniesFromIndexByUser(this.form.value)
      .subscribe(data => {
        this.ngOnInit()
      })
  }

  uncheckAll() {
    this.checkboxes.forEach((element) => {
      element.nativeElement.checked = false
    })
    const companiess: FormArray = this.form.get('companies') as FormArray
    while(companiess.length != 0){
      companiess.removeAt(0)
    }
    
  }

  deleteAll() {
    this.ind.deleteNewIndex()
      .subscribe(data => {
        this.ngOnInit()
      })
  }

  saveCompanyData(name: string, ticker: string, price: any, profit: any): void {
    this.news.saveStockName(name)
    this.pf.saveStockComponents(name, ticker, price, profit)
  }

  saveTicker(ticker: string, price: string): void {
    this.pf.saveDataFromPersonalIndex(ticker, price)
  }

}
