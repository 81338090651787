import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CustomValidatorService } from 'src/app/services/custom-validator.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  changePasswordForm: FormGroup
  //emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

  constructor(private formBuilder: FormBuilder, private router: Router, private auth: AuthService, 
              private custom: CustomValidatorService) { }

  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]      
    },{
      validator: this.custom.MustMatch('password', 'confirmPassword')
    })     
  }


  changePassword(){
    if (this.changePasswordForm.valid){
        this.auth.changePassword(this.changePasswordForm.value).subscribe(
        res => {
          const msg = res
          this.sendMsgConfirmation(msg)
          this.router.navigateByUrl('/auth/msg-confirmation-inside')
        },
        err => {
          const msg = err.error.detail
          this.sendMsgConfirmation(msg)          
          console.error(err)
          this.router.navigateByUrl('/auth/msg-confirmation-inside')
        }
      )
    }
  }

  sendMsgConfirmation(text: string): void{
    this.auth.sendMsg(text)
  }

}
