import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup

  constructor(private formBuilder: FormBuilder, private router: Router, 
              private auth: AuthService) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  //compruebo que el campo sea valido
  isFieldInvalid(field: string) {
    return (
      !this.loginForm.get(field).valid
    )
  }
  isFieldUnTouched(field: string) {
    return (
      !this.loginForm.get(field).untouched
    )
  }

  login() {
    if(this.loginForm.valid){
      this.auth.login(this.loginForm.value).subscribe(
        () => {
          this.router.navigateByUrl('/home')
        },
        err => {
          const msg = err.error.detail
          this.sendMsgConfirmation(msg)          
          console.error(err)
          this.router.navigateByUrl('/auth/msg-confirmation-outside')
        }
      )
    }
  }

  sendMsgConfirmation(text: string): void{
    this.auth.sendMsg(text)
  }

}
