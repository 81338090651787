import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule } from 'ng2-charts';
import { NgxPaginationModule } from 'ngx-pagination';

import { RoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminUsersManagmentComponent } from './components/auth/admin-users-managment/admin-users-managment.component';
import { ChangePasswordComponent } from './components/auth/change-password/change-password.component';
import { DeleteAccountComponent } from './components/auth/deleteAccount/delete-account/delete-account.component';
import { IfDeleteCheckAccountComponent } from './components/auth/deleteAccount/if-delete-check-account/if-delete-check-account.component';
import { LoginComponent } from './components/auth/login/login.component';
import { AskEmailComponent } from './components/auth/recoveryPassword/ask-email/ask-email.component';
import { ChangeRecoveredPasswordComponent } from './components/auth/recoveryPassword/change-recovered-password/change-recovered-password.component';
import { ActivationAccountComponent } from './components/auth/registerAccount/activation-account/activation-account.component';
import { RegisterComponent } from './components/auth/registerAccount/register/register.component';
import { UserProfileComponent } from './components/auth/user-profile/user-profile.component';
import { HomeComponent } from './components/home/home.component';
import { IndexesComponent } from './components/indexes/indexes.component';
import { LayoutComponent } from './components/layout/layout.component';
import { ConfirmationMessageInsideComponent } from './components/messages/confirmation-message-inside/confirmation-message-inside.component';
import { ConfirmationMessageOutsideComponent } from './components/messages/confirmation-message-outside/confirmation-message-outside.component';
import { HeaderComponent } from './components/navigation/header/header.component';
import { SidenavListComponent } from './components/navigation/sidenav-list/sidenav-list.component';
import { NewIndexComponent } from './components/personal/new-index/new-index.component';
import { CompanyInfoComponent } from './components/personal/news/company-info/company-info.component';
import { GraphComponent } from './components/personal/news/graphTime/graph/graph.component';
import { OneMonthComponent } from './components/personal/news/graphTime/one-month/one-month.component';
import { SixMonthsComponent } from './components/personal/news/graphTime/six-months/six-months.component';
import { ThreeMonthsComponent } from './components/personal/news/graphTime/three-months/three-months.component';
import { NewsComponent } from './components/personal/news/news/news.component';
import { PortfolioInfoByCompanyComponent } from './components/personal/news/portfolio-info-by-company/portfolio-info-by-company.component';
import { BalanceComponent } from './components/personal/portfolio/balance/balance.component';
import { BuyComponent } from './components/personal/portfolio/buy/buy.component';
import { OperationsRecordComponent } from './components/personal/portfolio/operations-record/operations-record.component';
import { PortfolioComponent } from './components/personal/portfolio/portfolio/portfolio.component';
import { AuthGuard } from './guards/auth.guard';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { MaterialModule } from './material/material.module';
import { AuthService } from './services/auth.service';
import { PageNotFoundComponent } from './components/navigation/page-not-found/page-not-found.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    LayoutComponent,
    SidenavListComponent,
    LoginComponent,
    NewsComponent,
    RegisterComponent,
    ActivationAccountComponent,
    AskEmailComponent,
    ChangeRecoveredPasswordComponent,
    IfDeleteCheckAccountComponent,
    DeleteAccountComponent,
    UserProfileComponent,
    AdminUsersManagmentComponent,
    ChangePasswordComponent,
    NewIndexComponent,
    GraphComponent,
    ConfirmationMessageInsideComponent,
    ConfirmationMessageOutsideComponent,
    IndexesComponent,
    PortfolioComponent,
    BuyComponent,
    BalanceComponent,
    OperationsRecordComponent,
    CompanyInfoComponent,
    PortfolioInfoByCompanyComponent,
    OneMonthComponent,
    ThreeMonthsComponent,
    SixMonthsComponent,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
    ChartsModule,
    NgxPaginationModule,
    
    
  ],
  providers: [AuthService, DatePipe, AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
