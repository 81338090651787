import { Component, OnInit } from '@angular/core';
import { IndexService } from 'src/app/services/index.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { PricesService } from 'src/app/services/prices.service';

@Component({
  selector: 'app-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.css'],
  styles: [
    `
      .greenClass { color: green }
      .redClass { color: red }
    `
  ]
})
export class CompanyInfoComponent implements OnInit {

  companyInfo: Object = {}
  indexName: string

  constructor(private pf: PortfolioService, private ind: IndexService) { }

  ngOnInit() {
    this.pf.companyInfo$
    .subscribe(data => {
      this.companyInfo = data
    })
    
    //get the Ibex35 name
    this.ind.getIndexName(this.companyInfo['ticker'])
    .subscribe(
      (data) => { // Success
        this.indexName = data        
      },
      (err) => {
        console.error(err.error.detail)
      }
    )
  }

}
