import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { IAdminInfoUser } from '../interfaces/admin';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  private URL_BACKEND = environment.remoteUrl

  constructor(private http: HttpClient) { }


  public getUsers(): Observable<IAdminInfoUser[]> {
    return this.http.get<IAdminInfoUser[]>(this.URL_BACKEND + 'admin/get-info-user')
  }
  
  public updateStateUser(email: string): Observable<string> {
    return this.http.put<string>(this.URL_BACKEND + 'admin/enable-disable-account/' + email, null)
  }

  public changeBalanceService(balance: string, email: string): Observable<string> {
    const balanceObj = {'balance': balance}
    return this.http.post<string>(this.URL_BACKEND + 'admin/change-balance/' + email, balanceObj)
  }
}
