import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-activation-account',
  templateUrl: './activation-account.component.html',
  styleUrls: ['./activation-account.component.css']
})
export class ActivationAccountComponent implements OnInit {

  constructor(private route: ActivatedRoute, private auth: AuthService, private router: Router) { }


  token: string


  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.token = params.get('token')
    })
      this.auth.activateAccount(this.token).subscribe(
        res => {
          const msg = res
          this.sendMsgConfirmation(msg)
          this.router.navigateByUrl('/auth/msg-confirmation-outside')
        },
        err => {
          const msg = err.error.detail
          this.sendMsgConfirmation(msg)          
          console.error(err)
          this.router.navigateByUrl('/auth/msg-confirmation-outside')
        }
      )
    
  }

  sendMsgConfirmation(text: string): void{
    this.auth.sendMsg(text)
  }
}
