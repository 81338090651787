import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { IPortfolio } from 'src/app/interfaces/portfolio';
import { PortfolioService } from 'src/app/services/portfolio.service';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css'],
  styles: [
    `
      .greenClass { color: green }
      .redClass { color: red }
    `
  ]

})
export class PortfolioComponent implements OnInit {

  trades: IPortfolio[] = []
  dateBool: boolean = false
  profitability: string[] = []
  isLoaded: boolean = false
  formatDate: string = 'dd/MM/yyyy hh:mm:ss a'  // para formatear la fecha a esto
  dateNow : number // aqui recogemos la fecha actual

  

  constructor(private pf: PortfolioService, private datePipe: DatePipe) {
    
  }

  ngOnInit() {
    this.pf.getPortfolio()
    .subscribe(
      (data) => { // Success
        this.trades = data['portfolio']
      },
      (err) => {
        console.error(err.error.detail)
      }
    )

    this.pf.getPortfolioProfitability()
    .subscribe(
      (data) => { // Success
        this.profitability = data       
        this.isLoaded = true
      },
      (err) => {
        console.error(err.error.detail)
      }
    )
  }

  closeTrade(index, trade, profitability) {
    this.dateNow = Date.now()
    trade.finalDate = this.datePipe.transform(this.dateNow, this.formatDate) 
    trade.profitability = profitability
    this.trades.splice(index, 1)
    this.pf.deleteTrade(trade)
    .subscribe(
      () => {},  
      (err) => {
        console.error(err.error.detail)
      }
    )    
  }

  onResetFromParentToSon() {
    this.ngOnInit()
  }

}
