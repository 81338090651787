import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { IPortfolio } from '../interfaces/portfolio';

@Injectable({
  providedIn: 'root'
})
export class RecordService {

  URL_BACKEND = environment.remoteUrl

  constructor(private http: HttpClient) { }


  public getRecords(): Observable<IPortfolio[]> {
    return this.http.get<IPortfolio[]>(this.URL_BACKEND + 'record/get-records')
  }

  public getRecordsByTicker(ticker: string): Observable<IPortfolio[]> {
    return this.http.get<IPortfolio[]>(this.URL_BACKEND + 'record/get-records-by-ticker/' + ticker)
  }
}
