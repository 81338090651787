import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminUsersManagmentComponent } from './components/auth/admin-users-managment/admin-users-managment.component';
import { ChangePasswordComponent } from './components/auth/change-password/change-password.component';
import { DeleteAccountComponent } from './components/auth/deleteAccount/delete-account/delete-account.component';
import { IfDeleteCheckAccountComponent } from './components/auth/deleteAccount/if-delete-check-account/if-delete-check-account.component';
import { LoginComponent } from './components/auth/login/login.component';
import { AskEmailComponent } from './components/auth/recoveryPassword/ask-email/ask-email.component';
import { ChangeRecoveredPasswordComponent } from './components/auth/recoveryPassword/change-recovered-password/change-recovered-password.component';
import { ActivationAccountComponent } from './components/auth/registerAccount/activation-account/activation-account.component';
import { RegisterComponent } from './components/auth/registerAccount/register/register.component';
import { UserProfileComponent } from './components/auth/user-profile/user-profile.component';
import { HomeComponent } from './components/home/home.component';
import { IndexesComponent } from './components/indexes/indexes.component';
import { ConfirmationMessageInsideComponent } from './components/messages/confirmation-message-inside/confirmation-message-inside.component';
import { ConfirmationMessageOutsideComponent } from './components/messages/confirmation-message-outside/confirmation-message-outside.component';
import { PageNotFoundComponent } from './components/navigation/page-not-found/page-not-found.component';
import { NewsComponent } from './components/personal/news/news/news.component';
import { OperationsRecordComponent } from './components/personal/portfolio/operations-record/operations-record.component';
import { PortfolioComponent } from './components/personal/portfolio/portfolio/portfolio.component';
import { AdminGuard } from './guards/admin.guard';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent},
  { path: 'register', component: RegisterComponent},//comentado
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
  { path: 'indexes', component: IndexesComponent, canActivate: [AuthGuard]},
  { path: 'record', component: OperationsRecordComponent, canActivate: [AuthGuard]},
  { path: 'news', component: NewsComponent, canActivate: [AuthGuard]},
  { path: 'portfolio', component: PortfolioComponent, canActivate: [AuthGuard]},
  { path: 'auth/activate-account/:token', component: ActivationAccountComponent},
  { path: 'auth/ask-email', component: AskEmailComponent},//comentado
  { path: 'auth/msg-confirmation-inside', component: ConfirmationMessageInsideComponent},
  { path: 'auth/msg-confirmation-outside', component: ConfirmationMessageOutsideComponent},
  { path: 'auth/change-recovery-password/:token', component: ChangeRecoveredPasswordComponent},//comentado
  { path: 'auth/if-delete-check-account', component: IfDeleteCheckAccountComponent},
  { path: 'auth/delete-account/:token', component: DeleteAccountComponent },
  { path: 'auth/user-profile', component: UserProfileComponent, canActivate: [AuthGuard]},  
  { path: 'auth/change-Password', component: ChangePasswordComponent, canActivate: [AuthGuard] },
  { path: 'admin/users-managment', component: AdminUsersManagmentComponent, canActivate: [AuthGuard, AdminGuard]},
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { useHash: false })
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class RoutingModule { }
