import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IPortfolio, IStockComponents } from '../interfaces/portfolio';

@Injectable({
  providedIn: 'root'
})
export class PortfolioService { 

  URL_BACKEND = environment.remoteUrl


  ticker: string
  initialPrice: string

  private companyInfo = new BehaviorSubject<IStockComponents>(null)
  companyInfo$ = this.companyInfo.asObservable()


  constructor(private http: HttpClient) { }  

  public saveDataFromPersonalIndex(ticker: string, price: string): void {
      localStorage.setItem('ticker', ticker)
      localStorage.setItem('price', price)
    }

  public savePortfolio(data: IPortfolio): Observable<string> {    
    return this.http.post<string>(this.URL_BACKEND + 'portfolio/save-new-portfolio', data)
  }

  public getPortfolio(): Observable<IPortfolio[]> {
    return this.http.get<IPortfolio[]>(this.URL_BACKEND + 'portfolio/get-portfolio')
  }

  public deleteTrade(data: IPortfolio): Observable<string> {
    return this.http.put<string>(this.URL_BACKEND + 'portfolio/delete-operation', data)
  }

  public getPortfolioProfitability(): Observable<string[]> {
    return this.http.get<string[]>(this.URL_BACKEND + 'portfolio/get-profitability-portfolio')
  }

  public getBalance(): Observable<number> {
    return this.http.get<number>(this.URL_BACKEND + 'portfolio/get-balance')
  }
  
  public getDataForPortfolio() {   
    const data = {
      "ticker": localStorage.getItem('ticker'),
      "initialPrice": localStorage.getItem('price'),
      "currentPrice": "0",
      "profitability": "0",
      "select": "",
      "shares": "",
      "initialDate": "",
      "finalDate": "0",
      "priceUpdate": false
    }

    localStorage.removeItem('ticker')
    localStorage.removeItem('price')
    return data

  }

  public getLiveOperations(ticker: string): Observable<IPortfolio[]> {
    return this.http.get<IPortfolio[]>(this.URL_BACKEND + 'portfolio/get-live-operations/' + ticker)
  }

  public saveStockComponents(name: string, ticker: string, price: string, profit: string): void {
    const data = {
      "name": name,
      "ticker": ticker,
      "price": price,
      "profit": profit
    }
    this.companyInfo.next(data)
  }

  
  public setPriceCurrent(): Observable<string> {
    return this.http.post<string>(this.URL_BACKEND + 'portfolio/set-current-price', null)
  }

}
