import { Component, OnInit } from '@angular/core';
import { INews } from 'src/app/interfaces/news';
import { AuthService } from 'src/app/services/auth.service';
import { NewsService } from 'src/app/services/news.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css'],
  styles: [
    `
      .greenClass { color: green }
      .redClass { color: red }
    `
  ]
})
export class NewsComponent implements OnInit {

  news_list: INews[] = []
  config: any
  username: string

  //pagination
  constructor(private news: NewsService, private auth: AuthService) {
    this.config = {
      itemsPerPage: 5,
      currentPage: 1,
      totalItems: this.news_list.length
    }
  }


  ngOnInit() {
    this.news.getNews()
    .subscribe(
      (data) => { // Success
        this.news_list = data
      },
      (err) => {
        console.error(err.error.detail);
      }
    )

    this.auth.username$
    .subscribe(
      (data) => { // Success
        this.username = data        
      }
    )
  }

  //los titulos de las noticias son unicos en la coleccion
  onSaveCheckedNews(title: string) {
    this.news.saveUserNewsChecked(title)
    .subscribe(
      (data) => { // Success
        this.ngOnInit()
      },
      (err) => {
        console.error(err.error.detail);
      })
  }

  pageChanged(event){
    this.config.currentPage = event;
  }

}
