import { Component, OnInit } from '@angular/core';
import * as Chart from 'chart.js';
import { IStockComponents } from 'src/app/interfaces/portfolio';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { PricesService } from 'src/app/services/prices.service';

@Component({
  selector: 'app-six-months',
  templateUrl: './six-months.component.html',
  styleUrls: ['./six-months.component.css']
})
export class SixMonthsComponent implements OnInit {

  dataX = []
  dataY = []
  ticker: string
  time: string = "6mo"
  companyInfo: IStockComponents
  onLabelTime: string = "6 Months"

  constructor(private price: PricesService, private pf: PortfolioService) { }

  ngOnInit() {
      this.pf.companyInfo$.subscribe(data => this.companyInfo = data) 
      this.price.getGraph(this.companyInfo['ticker'], this.time).subscribe(data => {

      this.dataX = data.map(datum => (datum.date))
      this.dataY = data.map(datum => (datum.close))
        
      let canvas= <HTMLCanvasElement> document.getElementById("canvas1")
      let ctx = canvas.getContext('2d')

      let chart = new Chart(ctx, {
        type: 'line',
        data: {
        
          labels: this.dataX,
          datasets: [{
              label: this.onLabelTime,
              data: this.dataY,
              borderColor: 'blue',
              borderWidth: 1,
              fill: false,
          }]
        },
        options: {
            legend: {
                display: false      
        },
          elements: {
            point:{
                radius: 0
            }
        },
          responsive: true,
              tooltips: {
                  custom: function(tooltipModel) {
                      if (tooltipModel.body && tooltipModel.body[0].lines && tooltipModel.body[0].lines[0].indexOf("Income") == -1) {
                          tooltipModel.opacity = 0;
                      }
                  }
                },
          scales: {
            xAxes: [
              {
                display: true
              }
            ],
            yAxes: [
              {
                display: true
              }
            ]
          },
          
        }
      })
      //
    })
  }

}
