import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { BehaviorSubject } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from 'src/environments/environment';

import { IToken } from '../interfaces/token'

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  //save messages from components when they subscribe to the Observable
  private msgConfirmation = new BehaviorSubject<string>(' ')

  msgConfirmation$ = this.msgConfirmation.asObservable()
  // kind of user by role
  private userType = new BehaviorSubject<string>(' ')
  userType$ = this.userType.asObservable()

  private username = new BehaviorSubject<string>(' ')
  username$ = this.username.asObservable()
  
  URL_BACKEND = environment.remoteUrl
  
  private loggedIn = new BehaviorSubject<boolean>(false)

  constructor(private http: HttpClient, private router: Router) { }
  
  //getter
  get isLoggedIn(): Observable<boolean> {
    var token = localStorage.getItem('access_token')
    if(token){      
      return this.loggedIn.asObservable()
    }else{
      this.loggedIn.next(false)
      return this.loggedIn.asObservable()
    }
  }


  public isLogged(): boolean {
    const token = localStorage.getItem('access_token')
    if(token){
      return true
    }else{
      return false
    }
  }

  public getToken(): string {
    return localStorage.getItem('access_token')    
  }

  //get the role from token
  private getRole(jwt: string): string {
    var jwtData = jwt.split('.')[1]
    var decodedJwtJsonData = window.atob(jwtData)
    var decodedJwtData = JSON.parse(decodedJwtJsonData)
    var dicValue = decodedJwtData['scopes']
    return dicValue[0]
  }
  

  //get the username from token
  private getUsername(jwt: string): string {
    var jwtData = jwt.split('.')[1]
    var decodedJwtJsonData = window.atob(jwtData)
    var decodedJwtData = JSON.parse(decodedJwtJsonData)
    var dicValue = decodedJwtData['sub']
    return dicValue
  }

  private parseRegister(loginForm): FormData {
    const formData = new FormData();
    formData.append('email', loginForm.email);
    formData.append('password', loginForm.password);
    //formData.append('grant_type', 'password');
    return formData
  }

  public register(formData): Observable<string> {
    const auxData = this.parseRegister(formData)
    return this.http.post<string>(this.URL_BACKEND + 'register', auxData)
  }


  private parseLogin(loginForm): FormData {
    const formData = new FormData();
    formData.append('username', loginForm.username);
    formData.append('password', loginForm.password);
    return formData
  }

  //login, save the token, get back an IToken object
  public login(formData): Observable<IToken> {
    var auxData = this.parseLogin(formData)
    return this.http.post<IToken>(this.URL_BACKEND + 'login', auxData)
          .pipe(map((data: IToken) => {
            localStorage.setItem('access_token', data.access_token)
            var rol = this.getRole(data.access_token)
            this.userType.next(rol)
            var username_aux = this.getUsername(data.access_token)
            this.username.next(username_aux)
            this.loggedIn.next(true)            
            return data
        }))
    }
  public logout(): void {
    //console.log(this.getToken())
    localStorage.removeItem('access_token')
    //console.log(this.getToken())
    this.loggedIn.next(false)
    this.router.navigateByUrl('/login')
  }


  public activateAccount(token: string): Observable<string>{
    //console.log(token)
    return this.http.post<string>(this.URL_BACKEND + 'activate-account/' + token, null)                    
  }

  private parseEmail(loginForm): FormData {
    const formData = new FormData();
    formData.append('email', loginForm.email);
    return formData
  }


  public askEmail(formData): Observable<string>{
    const auxData = this.parseEmail(formData)
    return this.http.post<string>(this.URL_BACKEND + 'create-token-recovery-password', auxData)
  }

  //update the observable
  public sendMsg(text: string){
    this.msgConfirmation.next(text)
  }

  private parsePassword(loginForm): FormData {
    const formData = new FormData()
    formData.append('token', loginForm.token)
    formData.append('password', loginForm.password)
    return formData
  }

  public sendTokenAndPassword(formData): Observable<string>{
    const auxData = this.parsePassword(formData)
    return this.http.post<string>(this.URL_BACKEND + 'recovery-password', auxData)
  }
  
  public checkAccount(formData): Observable<string> {
    const auxData = this.parseLogin(formData)
    return this.http.post<string>(this.URL_BACKEND + 'create-token-delete-account', auxData)
  }

  public deleteAccount(token: string): Observable<string>{
    console.log(token)
    return this.http.post<string>(this.URL_BACKEND + 'delete-account/' + token, null)                    
  }

  private parseChangePassword(form): FormData {
    const formData = new FormData()
    formData.append('password', form.password)
    return formData
  }

  public changePassword(formData): Observable<string> {
    const auxData = this.parseChangePassword(formData)
    return this.http.post<string>(this.URL_BACKEND + 'change-password', auxData)
  }
}
