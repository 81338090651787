import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-ask-email',
  templateUrl: './ask-email.component.html',
  styleUrls: ['./ask-email.component.css']
})
export class AskEmailComponent implements OnInit {

  askEmailForm: FormGroup

  constructor(private formBuilder: FormBuilder, private router: Router, private auth: AuthService) { }


  ngOnInit() {
    this.askEmailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    })
  }


  askEmail(){
    if (this.askEmailForm.valid){
      this.auth.askEmail(this.askEmailForm.value).subscribe(
        res => {
          const msg = res
          this.sendMsgConfirmation(msg)
          this.router.navigateByUrl('/auth/msg-confirmation-outside')
        },
        err => {
          const msg = err.error.detail
          this.sendMsgConfirmation(msg)          
          console.error(err)
          this.router.navigateByUrl('/auth/msg-confirmation-outside')
        }
      )
    }
  }

  sendMsgConfirmation(text: string): void{
    this.auth.sendMsg(text)
  }

}
