import { Component, OnInit } from '@angular/core';
import { IPortfolio, IStockComponents } from 'src/app/interfaces/portfolio';
import { PortfolioService } from 'src/app/services/portfolio.service';

@Component({
  selector: 'app-portfolio-info-by-company',
  templateUrl: './portfolio-info-by-company.component.html',
  styleUrls: ['./portfolio-info-by-company.component.css'],
  styles: [
    `
      .greenClass { color: green }
      .redClass { color: red }
    `
  ]
})
export class PortfolioInfoByCompanyComponent implements OnInit {

  companyInfo: IStockComponents
  portfolioData: IPortfolio[] = []

  constructor(private pf: PortfolioService) { }

  ngOnInit() {  
    this.pf.companyInfo$ 
    .subscribe(data => {
      this.companyInfo = data
    })

    this.pf.getLiveOperations(this.companyInfo['ticker'])
    .subscribe(data =>{
      this.portfolioData = data
    },
    (err) => {
      console.error(err.error.detail);
    })
    
  }

}
