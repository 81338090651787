import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private auth: AuthService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):
    Observable<boolean> {
      return this.auth.isLoggedIn
      .pipe(
        //take(1) takes the first value and after it unsubscribes automatically
        take(1),
        map((isLoggedIn: boolean) => {
          if (isLoggedIn){
            return true            
          }
          
          this.router.navigate(['/login'])
          return false
          
        })
      )
  }

}
