import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IPortfolio } from 'src/app/interfaces/portfolio';
import { PortfolioService } from 'src/app/services/portfolio.service';

@Component({
  selector: 'app-buy',
  templateUrl: './buy.component.html',
  styleUrls: ['./buy.component.css']
})
export class BuyComponent implements OnInit {

  data: IPortfolio  // fields of database
  buttonCount: number = 0  
  formatDate: string = 'dd/MM/yyyy hh:mm:ss a'
  dateNow : number // it takes the current date
  requiredMoney: number 
  balance: number 
  outOfBalance: boolean = false
  noData: boolean = false 

  @Output('reset') reset: EventEmitter<any> = new EventEmitter()


  constructor(private pf: PortfolioService, private datePipe: DatePipe) {
    
  }

  ngOnInit() {    
    this.data = this.pf.getDataForPortfolio()
    if(this.data.ticker == null){
      this.noData = true
    }

    this.data.select = "buy"
    this.dateNow = Date.now()
    this.data.initialDate = this.datePipe.transform(this.dateNow, this.formatDate)
    this.pf.getBalance()
    .subscribe(
      (data) => { // Success
        this.balance = data
      },
      (err) => {
        console.error(err.error.detail);
    })

  }

  createTrade() {
    this.pf.savePortfolio(this.data)
    .subscribe(
      (data) => { // Success
        this.buttonCount++   
        this.reset.emit(null)
      },
      (err) => {
        console.error(err.error.detail);
    })  
    
    
  }


  onSearchChange(searchValue: number) {    
    this.requiredMoney = searchValue * parseFloat(this.data['initialPrice'])
    if(this.requiredMoney > this.balance) {
      this.outOfBalance = true
    }else{
      this.outOfBalance = false
    }    
  }
  
}
