import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { IndexService } from 'src/app/services/index.service';

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.css']
})

export class SidenavListComponent implements OnInit {

  @Output() sidenavClose = new EventEmitter();
  isLoggedIn$: Observable<boolean>

  constructor(private ind: IndexService, private router: Router, private auth: AuthService) { }

  ngOnInit() {
    this.isLoggedIn$ = this.auth.isLoggedIn
  }
  
  public onSidenavClose = () => {
    this.sidenavClose.emit();
  }

  passVar(p): void {
    this.ind.saveNameIndex(p)
    this.router.navigateByUrl('/indexes')
  }

  goToDeleteAccount() {
    this.router.navigateByUrl("/auth/if-delete-check-account")
  }

  goToChangePassword() {
    this.router.navigateByUrl("/auth/change-Password")
  }

}