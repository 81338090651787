import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Cristonews';

  isOver600$: Observable<boolean> = this.breakpointObserver.observe(
      [Breakpoints.Small,Breakpoints.Medium,Breakpoints.Large,Breakpoints.XLarge])
    .pipe(
      map(result => result.matches)
    );

  constructor(private breakpointObserver: BreakpointObserver){}
}
