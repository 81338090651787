import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { INews } from '../interfaces/news';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  private stockName: string

  URL_BACKEND = environment.remoteUrl

  constructor(private http: HttpClient) { }

  public getNews(): Observable<INews[]> {
    return this.http.get<INews[]>(this.URL_BACKEND + 'news/get-news/' + this.stockName)
  }

  public getNumberOfNews(): Observable<number[]> {
    return this.http.get<number[]>(this.URL_BACKEND + 'news/get-number-of-news')
  }

  public saveUserNewsChecked(title: string): Observable<string> {
    return this.http.post<string>(this.URL_BACKEND + 'news/save-user-news-checked/' + title, null)
  }

  public saveStockName(name: string): void {
    this.stockName = name
  }
}
