import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-admin-users-managment',
  templateUrl: './admin-users-managment.component.html',
  styleUrls: ['./admin-users-managment.component.css']
})
export class AdminUsersManagmentComponent implements OnInit {

  users: any[] = []
  balance: string
  //bal: string[] = []

  constructor(private adminServ: AdminService, private router: Router) { }

  ngOnInit() {
    this.adminServ.getUsers()
    .subscribe(
      (data) => { // Success
        this.users = data
      },
      (err) => {
        console.error(err.error.detail);
      }
    )
  }

  setValor(userInfo) {
    var email = userInfo['email'];
    this.adminServ.updateStateUser(email)
    .subscribe(data => {    
      this.ngOnInit()      
    },
    err => {
      console.error(err.error.detail)
    })
  }

  isEnabled(userInfo){
    return userInfo['enable'];
  }
  
  changeBalance(email) {
    this.adminServ.changeBalanceService(this.balance, email)
    .subscribe(
      (data) => { // Success
        this.ngOnInit() 
      },
      (err) => {
        console.error(err.error.detail);
      }
    )
  } 
  

}
