import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class IndexService {

  URL_BACKEND = environment.remoteUrl
  auxIndex: string

  constructor(private http: HttpClient) { }

  public getIndex(): Observable<string[]> {
    return this.http.get<string[]>(this.URL_BACKEND + 'index/get-index/' + this.auxIndex)
  }

  public getTickersToMainIndex(): Observable<string[]> {
    return this.http.get<string[]>(this.URL_BACKEND + 'index/get-tickers-by-name/' + this.auxIndex)
  }

  public saveNewIndex(formData): Observable<string> {
    return this.http.post<string>(this.URL_BACKEND + 'index/save-new-index-by-user', formData)    
  }

  public getNewIndex(): Observable<string[]> {
    return this.http.get<string[]>(this.URL_BACKEND + 'index/get-new-index')
  }

  public getTickers(): Observable<string[]> {
    return this.http.get<string[]>(this.URL_BACKEND + 'index/get-tickers-by-user/')
  }

  public deleteCompaniesFromIndexByUser(formData): Observable<string> {
    return this.http.post<string>(this.URL_BACKEND + 'index/delete-companies-from-index-by-user', formData)    
  }

  //delete no tiene body
  public deleteNewIndex(): Observable<string> {
    return this.http.delete<string>(this.URL_BACKEND + 'index/delete-new-index')    
  }

  public getIndexName(ticker: string): Observable<string> {
    return this.http.get<string>(this.URL_BACKEND + 'index/get-index-name/' + ticker)
  }

  public saveNameIndex(name: string): void {
    this.auxIndex = name
  }
}
