import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Output() public sidenavToggle = new EventEmitter();
  //I declare an Observable to save the value from AuthService
  isLoggedIn$: Observable<boolean>
  userType: string = ' '

  constructor(private auth: AuthService) { }

  ngOnInit() {
    this.isLoggedIn$ = this.auth.isLoggedIn
    this.auth.userType$.subscribe(value => this.userType = value)
  }

  public onToggleSidenav = () => { 
    this.sidenavToggle.emit();
  }

  public logout() {
    this.auth.logout()
  }

}
