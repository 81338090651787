import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { IndexService } from 'src/app/services/index.service';

@Component({
  selector: 'app-indexes',
  templateUrl: './indexes.component.html',
  styleUrls: ['./indexes.component.css']
})
export class IndexesComponent implements OnInit {

  companies: string[] = []
  tickers: string[] = []
  form: FormGroup
  username: string
  
  //esta linea la uso para desmarcar todos los checkboxes
  @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef>

  constructor(private formBuilder: FormBuilder, 
    private ind: IndexService, private auth: AuthService, private router: Router) {
    this.form = this.formBuilder.group({
      companies: this.formBuilder.array([])
  })
  }

  ngOnInit(): void {
    this.ind.getIndex()
    .subscribe(
      (data) => { // Success
        this.companies = data['companies']
      },
      (err) => {
        console.error(err.error.detail);
      }
    )

    this.ind.getTickersToMainIndex()
    .subscribe(
      (data) => { // Success
        this.tickers = data
      },
      (err) => {
        console.error(err.error.detail);
      }
    ) 
    
  }

  onCheckboxChange(e) {
    const companiess: FormArray = this.form.get('companies') as FormArray;
    
    //si seleccionamos el checkbox se mete en la lista
    if (e.target.checked) {
      companiess.push(new FormControl(e.target.value))
    } else {
      let i: number = 0
      //aqui es para comprobar cuando ya no estan checkados
      companiess.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          companiess.removeAt(i)
          return
        }
        i++
      })
    }
  }

  submitForm() {
    this.auth.username$.subscribe(data => this.username = data)  
    this.ind.saveNewIndex(this.form.value)
      .subscribe(data => {
        this.router.navigateByUrl('/home')
      })
  }

  uncheckAll() {
    this.checkboxes.forEach((element) => {
      element.nativeElement.checked = false
    })
  }


}
