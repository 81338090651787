import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const accessToken = localStorage.getItem("access_token");
  

    if (accessToken) {
      const cloned = req.clone({
          headers: req.headers.set("Authorization",
              "Bearer " + accessToken)
      });

      return next.handle(cloned);
    }
    else {
      return next.handle(req); //retocar aqui errores
    }
  }
}
