import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IHistoricalPrices } from '../interfaces/prices';

@Injectable({
  providedIn: 'root'
})
export class PricesService {

  URL_BACKEND = environment.remoteUrl  


  constructor(private http: HttpClient) { }

  public getLastPrices() : Observable<string[]>{
    return this.http.get<string[]>(this.URL_BACKEND + 'prices/get-last-prices')
  }

  public getProfitabilities(): Observable<string[]>{
    return this.http.get<string[]>(this.URL_BACKEND + 'prices/get-profitabilities')
  }

  public getGraph(ticker: string, timer: string): Observable<IHistoricalPrices[]> {
    return this.http.get<IHistoricalPrices[]>(this.URL_BACKEND + 'prices/get-graph/' + ticker + '/' + timer)
  }

}
