import { Component, OnInit } from '@angular/core';
import { IPortfolio } from 'src/app/interfaces/portfolio';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { RecordService } from 'src/app/services/record.service';

@Component({
  selector: 'app-operations-record',
  templateUrl: './operations-record.component.html',
  styleUrls: ['./operations-record.component.css']
})
export class OperationsRecordComponent implements OnInit {

  data: IPortfolio[] = []
  ticker: string

  constructor(private record: RecordService, private pf: PortfolioService) { }

  ngOnInit() {

    this.record.getRecords()
    .subscribe(
      (data) => { // Success
        this.data = data   
      },
      (err) => {
        console.error(err.error.detail)
      }
    )
  }

  onFilter() {
    this.record.getRecordsByTicker(this.ticker)
    .subscribe(
      (data) => { // Success
        this.data = data   
      },
      (err) => {
        console.error(err.error.detail)
      }
    )
  }

  onReset() {
    this.ngOnInit()
    this.ticker = ''
  }

}
