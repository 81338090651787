import { Component, OnInit } from '@angular/core';
import { PortfolioService } from 'src/app/services/portfolio.service';

@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.css']
})
export class BalanceComponent implements OnInit {

  balance: number

  constructor(private pf: PortfolioService) { }

  ngOnInit() {
    this.pf.getBalance()
    .subscribe(
      (data) => { // Success
        this.balance = data
      },
      (err) => {
        console.error(err.error.detail)
      }
    )
  }

}
