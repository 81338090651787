import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CustomValidatorService } from 'src/app/services/custom-validator.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup
  //emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" //other option

  constructor(private formBuilder: FormBuilder, private router: Router, private auth: AuthService, 
              private custom: CustomValidatorService) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      //email: ['', Validators.required, Validators.pattern(this.emailPattern)], //other option with pattern
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]      
    },{
      validator: this.custom.MustMatch('password', 'confirmPassword')
    })     
  }


  register(){
    if (this.registerForm.valid){
      this.auth.register(this.registerForm.value).subscribe(
        res => {
          const msg = res
          this.sendMsgConfirmation(msg)
          this.router.navigateByUrl('/auth/msg-confirmation-outside')
        },
        err => {
          const msg = err.error.detail
          this.sendMsgConfirmation(msg)          
          console.error(err)
          this.router.navigateByUrl('/auth/msg-confirmation-outside')
        }
      )
    }
  }

  sendMsgConfirmation(text: string): void{
    this.auth.sendMsg(text)
  }

}
